<template>
  <div class="hold-transition">
    <loading v-if="cargando" />
    <div class="wrapper">
      <div class="content-wrapper">
        <section class="content-header">
          <div class="container-fluid">
            <div class="row">
              <div class="col-sm-6">
                <h5 class="mb-0">Postulación</h5>
              </div>
              <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item">
                    <router-link to="/">Home</router-link>
                  </li>
                  <li class="breadcrumb-item active">Carga Seca</li>
                  <li class="breadcrumb-item active">Nacionales</li>
                  <li class="breadcrumb-item active">Operaciones</li>
                  <li class="breadcrumb-item active">Postulaciones</li>
                  <li class="breadcrumb-item active">Asignar Servicios</li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section class="content">
          <div class="container-fluid">
            <div class="card">
              <div class="card-header">
                <div class="row">
                  <div class="col-md-12">
                    <h5 class="title">Postulación de Servicios</h5>
                  </div>
                </div>
              </div>
              <div class="card-body">
                <div class="row justify-content-md-center">
                  <div id="accordion" class="col-md-12">
                    <div class="card text-muted text-xs">
                      <div class="card-header bg-frontera">
                        <h4 class="card-title">
                          <a
                            class="d-block text-white"
                            data-toggle="collapse"
                            href="#collapseOne"
                          >
                            Detalle Solicitud - {{ solicitud.id }}
                          </a>
                        </h4>
                      </div>
                      <div
                        id="collapseOne"
                        class="collapse"
                        data-parent="#accordion"
                      >
                        <div class="card-body">
                          <div class="row">
                            <div class="col-md-4">
                              <ul class="ml-4 mb-0 fa-ul">
                                <li>
                                  <span class="fa-li">
                                    <i class="fas fa-lg fa-info"></i>
                                  </span>
                                  <strong>Detalle</strong>
                                  <div class="row">
                                    <div class="col-md-5">Tipo Solicitud:</div>
                                    <div class="col-md-7 mb-1">
                                      <strong>{{
                                        solicitud.nTipoSolicitud
                                      }}</strong>
                                    </div>
                                  </div>
                                  <div
                                    class="row"
                                    v-if="solicitud.tipo_emergencia"
                                  >
                                    <div class="col-md-5">Tipo Emergencia:</div>
                                    <div class="col-md-7 mb-1">
                                      <strong>{{
                                        solicitud.nTipoEmergencia
                                      }}</strong>
                                    </div>
                                  </div>
                                  <div class="row">
                                    <div class="col-md-5">Solicitante:</div>
                                    <div class="col-md-7 mb-1">
                                      <strong>{{
                                        solicitud.user
                                          ? solicitud.user.name
                                          : ""
                                      }}</strong>
                                    </div>
                                  </div>
                                  <div class="row">
                                    <div class="col-md-5">Material:</div>
                                    <div class="col-md-7 mb-1">
                                      <i
                                        class="fa"
                                        :class="
                                          solicitud.material_inventarios
                                            ? 'fa-check text-success'
                                            : 'fa-times-circle text-danger'
                                        "
                                      ></i>
                                    </div>
                                  </div>
                                  <div class="row">
                                    <div class="col-md-5">Estado:</div>
                                    <div class="col-md-7 mb-1">
                                      <span
                                        class="badge"
                                        :class="
                                          solicitud.estado == 1
                                            ? 'bg-warning'
                                            : solicitud.estado == 2
                                            ? 'bg-success'
                                            : 'bg-secondary'
                                        "
                                      >
                                        {{ solicitud.nEstado }}
                                      </span>
                                    </div>
                                  </div>
                                  <div class="row">
                                    <div class="col-md-5">Justificación:</div>
                                    <div class="col-md-7 mb-1">
                                      <small>{{
                                        solicitud.justificacion
                                      }}</small>
                                    </div>
                                  </div>
                                  <div class="row">
                                    <div class="col-md-5">Observación:</div>
                                    <div class="col-md-7 mb-1">
                                      <small>{{
                                        solicitud.observaciones
                                      }}</small>
                                    </div>
                                  </div>
                                </li>
                              </ul>
                            </div>
                            <div
                              class="col-md-4"
                              v-if="
                                asignacion.solicitud_tipo_servicio &&
                                  asignacion.solicitud_tipo_servicio.rutas
                                    .length > 0
                              "
                            >
                              <ul class="ml-4 mb-0 fa-ul">
                                <li>
                                  <span class="fa-li">
                                    <i class="fas fa-road"></i>
                                  </span>
                                  <strong>Ruta Origen</strong>
                                  <div class="row">
                                    <div class="col-md-5">Sitio:</div>
                                    <div class="col-md-7 mb-1">
                                      <strong>{{
                                        asignacion.solicitud_tipo_servicio
                                          .rutas[0].nombre
                                      }}</strong>
                                    </div>
                                  </div>
                                  <div class="row">
                                    <div class="col-md-5">Dirección:</div>
                                    <div class="col-md-7 mb-1">
                                      <strong>{{
                                        asignacion.solicitud_tipo_servicio
                                          .rutas[0].direccion
                                      }}</strong>
                                    </div>
                                  </div>
                                  <div class="row">
                                    <div class="col-md-5">Nombre Contacto:</div>
                                    <div class="col-md-7 mb-1">
                                      <strong>{{
                                        asignacion.solicitud_tipo_servicio
                                          .rutas[0].nombre_contacto
                                      }}</strong>
                                    </div>
                                  </div>
                                  <div class="row">
                                    <div class="col-md-5">Número:</div>
                                    <div class="col-md-7 mb-1">
                                      <strong>{{
                                        asignacion.solicitud_tipo_servicio
                                          .rutas[0].telefono_contacto
                                      }}</strong>
                                    </div>
                                  </div>
                                  <div class="row">
                                    <div class="col-md-5">Correo:</div>
                                    <div class="col-md-7 mb-1">
                                      <strong>{{
                                        asignacion.solicitud_tipo_servicio
                                          .rutas[0].email_contacto
                                      }}</strong>
                                    </div>
                                  </div>
                                </li>
                              </ul>
                            </div>
                            <div
                              class="col-md-4"
                              v-if="
                                asignacion.solicitud_tipo_servicio &&
                                  asignacion.solicitud_tipo_servicio.rutas
                                    .length > 1
                              "
                            >
                              <ul class="ml-4 mb-0 fa-ul">
                                <li>
                                  <span class="fa-li">
                                    <i class="fas fa-road"></i>
                                  </span>
                                  <strong>Ruta Destino</strong>
                                  <div class="row">
                                    <div class="col-md-5">Sitio:</div>
                                    <div class="col-md-7 mb-1">
                                      <strong>{{
                                        asignacion.solicitud_tipo_servicio
                                          .rutas[1].nombre
                                      }}</strong>
                                    </div>
                                  </div>
                                  <div class="row">
                                    <div class="col-md-5">Dirección:</div>
                                    <div class="col-md-7 mb-1">
                                      <strong>{{
                                        asignacion.solicitud_tipo_servicio
                                          .rutas[1].direccion
                                      }}</strong>
                                    </div>
                                  </div>
                                  <div class="row">
                                    <div class="col-md-5">Nombre Contacto:</div>
                                    <div class="col-md-7 mb-1">
                                      <strong>{{
                                        asignacion.solicitud_tipo_servicio
                                          .rutas[1].nombre_contacto
                                      }}</strong>
                                    </div>
                                  </div>
                                  <div class="row">
                                    <div class="col-md-5">Número:</div>
                                    <div class="col-md-7 mb-1">
                                      <strong>{{
                                        asignacion.solicitud_tipo_servicio
                                          .rutas[1].telefono_contacto
                                      }}</strong>
                                    </div>
                                  </div>
                                  <div class="row">
                                    <div class="col-md-5">Correo:</div>
                                    <div class="col-md-7 mb-1">
                                      <strong>{{
                                        asignacion.solicitud_tipo_servicio
                                          .rutas[1].email_contacto
                                      }}</strong>
                                    </div>
                                  </div>
                                </li>
                              </ul>
                            </div>
                            <hr class="w-100" />
                            <div
                              class="col-md-4 mt-2"
                              v-if="
                                asignacion.solicitud_tipo_servicio &&
                                  asignacion.solicitud_tipo_servicio.det_cargas
                                    .length > 0
                              "
                            >
                              <ul class="ml-4 mb-0 fa-ul">
                                <li>
                                  <span class="fa-li">
                                    <i class="fas fa-lg fa-cogs"></i>
                                  </span>
                                  <strong
                                    >Material -
                                    {{
                                      asignacion.solicitud_tipo_servicio
                                        .det_cargas[0].descripcion_material
                                    }}</strong
                                  >
                                  <div class="row">
                                    <div class="col-md-5"># Pedido:</div>
                                    <div class="col-md-7 mb-1">
                                      <strong>{{
                                        asignacion.solicitud_tipo_servicio
                                          .det_cargas[0].no_pedido_compra
                                      }}</strong>
                                    </div>
                                  </div>
                                  <div class="row">
                                    <div class="col-md-5">Peso:</div>
                                    <div class="col-md-7 mb-1">
                                      <strong
                                        >{{
                                          asignacion.solicitud_tipo_servicio
                                            .det_cargas[0].peso
                                        }}
                                        <small>(Kg)</small></strong
                                      >
                                    </div>
                                  </div>
                                  <div class="row">
                                    <div class="col-md-5">Largo:</div>
                                    <div class="col-md-7 mb-1">
                                      <strong>{{
                                        asignacion.solicitud_tipo_servicio
                                          .det_cargas[0].largo
                                      }}</strong>
                                    </div>
                                  </div>
                                  <div class="row">
                                    <div class="col-md-5">Alto:</div>
                                    <div class="col-md-7 mb-1">
                                      <strong>{{
                                        asignacion.solicitud_tipo_servicio
                                          .det_cargas[0].alto
                                      }}</strong>
                                    </div>
                                  </div>
                                  <div class="row">
                                    <div class="col-md-5">Ancho:</div>
                                    <div class="col-md-7 mb-1">
                                      <strong>{{
                                        asignacion.solicitud_tipo_servicio
                                          .det_cargas[0].ancho
                                      }}</strong>
                                    </div>
                                  </div>
                                </li>
                              </ul>
                            </div>
                            <div
                              class="col-md-4"
                              v-if="asignacion.solicitud_tipo_servicio"
                            >
                              <ul class="ml-4 mb-0 fa-ul">
                                <li>
                                  <span class="fa-li">
                                    <i class="fas fa-lg fa-info"></i>
                                  </span>
                                  <strong>Asignación</strong>
                                  <div class="row">
                                    <div class="col-md-5">Empresa:</div>
                                    <div class="col-md-7 mb-1">
                                      <strong>{{
                                        asignacion.empresa
                                          ? asignacion.empresa.razon_social
                                          : ""
                                      }}</strong>
                                    </div>
                                  </div>
                                  <div class="row">
                                    <div class="col-md-5">
                                      Cantidad
                                      {{
                                        asignacion.solicitud_tipo_servicio
                                          .tipo_servicio == 1
                                          ? "Vehículos"
                                          : "Equipos"
                                      }}:
                                    </div>
                                    <div class="col-md-7 mb-1">
                                      <strong>{{
                                        asignacion.cantidad_vehiculos
                                      }}</strong>
                                    </div>
                                  </div>
                                  <div class="row">
                                    <div class="col-md-5">
                                      Tiempo de Servicio:
                                    </div>
                                    <div class="col-md-7 mb-1">
                                      <strong>{{
                                        asignacion.cantidad_servicios
                                      }}</strong>
                                      <small>
                                        {{
                                          asignacion.solicitud_tipo_servicio
                                            .tipo_cantidad == 1
                                            ? "(Viajes)"
                                            : "(Días)"
                                        }}</small
                                      >
                                    </div>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="card-body table_responsive pt-0"
                v-if="asignacion.solicitud_tipo_servicio"
              >
                <h5>Postulaciones</h5>
                <div class="row">
                  <div
                    v-for="(cant_vh,
                    indexCantVh) in asignacion.cantidad_vehiculos"
                    :key="cant_vh"
                    class="col-md-12"
                  >
                    <div
                      class="card card-outline card-teal collapsed-card mb-1"
                    >
                      <div class="card-header pt-2 pb-2">
                        <h3 class="card-title">
                          <small
                            ><b
                              >{{
                                asignacion.solicitud_tipo_servicio
                                  .tipo_servicio == 1
                                  ? asignacion.solicitud_tipo_servicio
                                      .nTipoRemolque
                                    ? asignacion.solicitud_tipo_servicio
                                        .nTipoVh +
                                      " - " +
                                      asignacion.solicitud_tipo_servicio
                                        .nTipoRemolque
                                    : asignacion.solicitud_tipo_servicio.nTipoVh
                                  : asignacion.solicitud_tipo_servicio.nTipoEqui
                              }}
                              - {{ cant_vh }}</b
                            ></small
                          >
                        </h3>
                        <div class="card-tools">
                          <button
                            type="button"
                            data-card-widget="collapse"
                            data-toggle="tooltip"
                            title="Collapse"
                            class="btn btn-tool"
                          >
                            <i class="fas fa-plus"></i>
                          </button>
                        </div>
                      </div>
                      <div
                        class="card-body table-responsive p-0"
                        style="display: none"
                      >
                        <table
                          class="table table-bordered table-striped table-hover table-sm text-nowrap"
                        >
                          <thead>
                            <tr>
                              <!-- Tipo Cantidad Viaje / Día -->
                              <th class="text-center">
                                {{
                                  asignacion.solicitud_tipo_servicio
                                    .tipo_cantidad == 1
                                    ? "Viaje"
                                    : "Día"
                                }}
                              </th>
                              <!-- Vehículo -->
                              <th
                                class="text-center"
                                v-if="
                                  asignacion.solicitud_tipo_servicio
                                    .tipo_servicio == 1
                                "
                              >
                                {{ asignacion.solicitud_tipo_servicio.nTipoVh }}
                                <input
                                  v-if="
                                    asignacion.cantidad_servicios !=
                                      cantPostulados[indexCantVh]
                                  "
                                  type="text"
                                  v-model="listasForms.equipo[indexCantVh]"
                                  :id="'slct_vehiculo_' + indexCantVh"
                                  class="form-control form-control-sm"
                                  @change="buscarVehiculos(indexCantVh, null)"
                                  :disabled="
                                    accion == 2 ||
                                      ![1, 2, 5].includes(
                                        asignacion.solicitud_tipo_servicio
                                          .solicitud.estado
                                      )
                                  "
                                />
                              </th>
                              <!-- Equipo -->
                              <th
                                class="text-center"
                                v-if="
                                  asignacion.solicitud_tipo_servicio
                                    .tipo_servicio == 2
                                "
                              >
                                EQUIPO
                                <input
                                  type="text"
                                  v-if="
                                    asignacion.cantidad_servicios !=
                                      cantPostulados[indexCantVh]
                                  "
                                  v-model="listasForms.equipo[indexCantVh]"
                                  :id="'slct_equipo_' + indexCantVh"
                                  class="form-control form-control-sm"
                                  @change="buscarEquipos(indexCantVh, null)"
                                  :disabled="
                                    accion == 2 ||
                                      ![1, 2, 5].includes(
                                        asignacion.solicitud_tipo_servicio
                                          .solicitud.estado
                                      )
                                  "
                                />
                                <div
                                  v-if="
                                    asignacion.solicitud_tipo_servicio
                                      .tipo_servicio == 2 &&
                                      listasForms.equipo_placa[indexCantVh]
                                  "
                                >
                                  <small
                                    >Placa:
                                    <strong>{{
                                      listasForms.equipo_placa[indexCantVh]
                                    }}</strong></small
                                  >
                                </div>
                                <div
                                  v-if="
                                    asignacion.solicitud_tipo_servicio
                                      .tipo_servicio == 2 &&
                                      listasForms.equipo_serial[indexCantVh]
                                  "
                                >
                                  <small
                                    >Serial:
                                    <strong>{{
                                      listasForms.equipo_serial[indexCantVh]
                                    }}</strong></small
                                  >
                                </div>
                              </th>
                              <!-- Remolque-->
                              <th
                                class="text-center"
                                v-if="
                                  asignacion.solicitud_tipo_servicio
                                    .tipo_servicio == 1 && remolque
                                "
                              >
                                {{
                                  asignacion.solicitud_tipo_servicio
                                    .nTipoRemolque
                                }}
                                <input
                                  type="text"
                                  v-if="
                                    asignacion.cantidad_servicios !=
                                      cantPostulados[indexCantVh]
                                  "
                                  v-model="listasForms.remolque[indexCantVh]"
                                  :id="'slct_remolque_' + indexCantVh"
                                  class="form-control form-control-sm"
                                  @change="buscarRemolques(indexCantVh, null)"
                                  :disabled="
                                    accion == 2 ||
                                      (![1, 2, 5].includes(
                                        asignacion.solicitud_tipo_servicio
                                          .solicitud.estado
                                      ) &&
                                        !remolque)
                                  "
                                />
                              </th>
                              <!-- Campo Conductor / Operario General -->
                              <th class="text-center">
                                {{
                                  asignacion.solicitud_tipo_servicio
                                    .tipo_servicio == 1
                                    ? "Conductor"
                                    : "Operario"
                                }}
                                <input
                                  type="text"
                                  v-if="
                                    asignacion.cantidad_servicios !=
                                      cantPostulados[indexCantVh]
                                  "
                                  v-model="listasForms.persona[indexCantVh]"
                                  :id="
                                    asignacion.solicitud_tipo_servicio
                                      .tipo_servicio == 1
                                      ? 'slct_conductor_' + indexCantVh
                                      : 'slct_operario_' + indexCantVh
                                  "
                                  class="form-control form-control-sm"
                                  @change="
                                    asignacion.solicitud_tipo_servicio
                                      .tipo_servicio == 1
                                      ? buscarConductores(indexCantVh, null)
                                      : buscarOperarios(indexCantVh, null)
                                  "
                                  :disabled="
                                    accion == 2 ||
                                      ![1, 2, 5].includes(
                                        asignacion.solicitud_tipo_servicio
                                          .solicitud.estado
                                      )
                                  "
                                />
                                <small>{{
                                  listasForms.persona_name[indexCantVh]
                                }}</small>
                              </th>
                              <!-- Campo Aparejador General -->
                              <th
                                class="text-center"
                                v-if="
                                  asignacion.solicitud_tipo_servicio
                                    .tipo_servicio == 2
                                "
                              >
                                Aparejador
                                <input
                                  type="text"
                                  v-if="
                                    asignacion.cantidad_servicios !=
                                      cantPostulados[indexCantVh]
                                  "
                                  v-model="listasForms.aparejador[indexCantVh]"
                                  :id="'slct_aparejador_' + indexCantVh"
                                  class="form-control form-control-sm"
                                  @change="
                                    buscarAparejadores(indexCantVh, null)
                                  "
                                  :disabled="
                                    accion == 2 ||
                                      ![1, 2, 5].includes(
                                        asignacion.solicitud_tipo_servicio
                                          .solicitud.estado
                                      )
                                  "
                                />
                                <small>{{
                                  listasForms.aparejador_name[indexCantVh]
                                }}</small>
                              </th>
                              <!-- Campo Fecha LLegada General -->
                              <th class="text-center">
                                Fecha Llegada Vehículo
                                <div class="row">
                                  <div class="col-6">
                                    <input
                                      type="date"
                                      v-model="
                                        listasForms.date_llegada_vh[indexCantVh]
                                      "
                                      placeholder="Fecha Llegada"
                                      class="form-control form-control-sm"
                                      :disabled="
                                        accion == 2 ||
                                          ![1, 2, 5].includes(
                                            asignacion.solicitud_tipo_servicio
                                              .solicitud.estado
                                          )
                                      "
                                      v-if="
                                        asignacion.cantidad_servicios !=
                                          cantPostulados[indexCantVh]
                                      "
                                    />
                                  </div>
                                  <div class="col-6">
                                    <input
                                      type="text"
                                      v-model="
                                        listasForms.hora_llegada_vh[indexCantVh]
                                      "
                                      placeholder="Hora Llegada"
                                      class="form-control form-control-sm"
                                      v-mask="{
                                        mask: '99:99',
                                        hourFormat: '23',
                                      }"
                                      :disabled="
                                        accion == 2 ||
                                          ![1, 2, 5].includes(
                                            asignacion.solicitud_tipo_servicio
                                              .solicitud.estado
                                          )
                                      "
                                      v-if="
                                        asignacion.cantidad_servicios !=
                                          cantPostulados[indexCantVh]
                                      "
                                    />
                                  </div>
                                </div>
                              </th>
                              <!-- Campo Estado General -->
                              <th class="text-center p-3">Estado</th>
                              <!-- Campo Acciones General -->
                              <th class="text-center p-3">
                                Acción
                                <div
                                  v-if="
                                    asignacion.cantidad_servicios !=
                                      cantPostulados[indexCantVh]
                                  "
                                  @click="save(indexCantVh, null)"
                                  :disabled="
                                    accion == 2 ||
                                      ![1, 2, 5].includes(
                                        asignacion.solicitud_tipo_servicio
                                          .solicitud.estado
                                      )
                                  "
                                >
                                  <button
                                    class="btn btn-default btn-sm bg-frontera-top-left text-white"
                                  >
                                    <i class="fas fa-save"></i>
                                  </button>
                                </div>
                              </th>
                            </tr>
                          </thead>
                          <tbody v-if="form">
                            <tr
                              v-for="(cant_sevicio,
                              indexDias) in asignacion.cantidad_servicios"
                              :key="cant_sevicio"
                            >
                              <td class="text-center">{{ cant_sevicio }}</td>
                              <!-- Campo Vehículo-->
                              <td
                                v-if="
                                  asignacion.solicitud_tipo_servicio
                                    .tipo_servicio == 1
                                "
                              >
                                <input
                                  type="text"
                                  class="form-control form-control-sm"
                                  v-model="form[indexCantVh][indexDias].equipo"
                                  :id="
                                    'slct_vehiculo_' +
                                      indexCantVh +
                                      '_' +
                                      indexDias
                                  "
                                  :disabled="
                                    accion == 2 ||
                                      selectVehi !=
                                        `${indexCantVh}_${indexDias}` ||
                                      hoy > form[indexCantVh][indexDias].fecha
                                  "
                                  @change="
                                    buscarVehiculos(indexCantVh, indexDias)
                                  "
                                />
                              </td>
                              <!-- Campo Equipo-->
                              <td
                                v-if="
                                  asignacion.solicitud_tipo_servicio
                                    .tipo_servicio == 2
                                "
                              >
                                <input
                                  type="text"
                                  class="form-control form-control-sm"
                                  v-model="form[indexCantVh][indexDias].equipo"
                                  :id="
                                    'slct_equipo_' +
                                      indexCantVh +
                                      '_' +
                                      indexDias
                                  "
                                  @change="
                                    buscarEquipos(indexCantVh, indexDias)
                                  "
                                  :disabled="
                                    accion == 2 ||
                                      selectVehi !=
                                        `${indexCantVh}_${indexDias}` ||
                                      hoy > form[indexCantVh][indexDias].fecha
                                  "
                                />
                                <div
                                  v-if="
                                    form[indexCantVh][indexDias].equipo_type ==
                                      'App\\Equipo' &&
                                      form[indexCantVh][indexDias].equipo_placa
                                  "
                                >
                                  <small
                                    >Placa:
                                    <strong>{{
                                      form[indexCantVh][indexDias].equipo_placa
                                    }}</strong></small
                                  >
                                </div>
                                <div
                                  v-if="
                                    form[indexCantVh][indexDias].equipo_type ==
                                      'App\\Equipo' &&
                                      form[indexCantVh][indexDias].equipo_serial
                                  "
                                >
                                  <small
                                    >Serial:
                                    <strong>{{
                                      form[indexCantVh][indexDias].equipo_serial
                                    }}</strong></small
                                  >
                                </div>
                              </td>
                              <!-- Campo Remolque -->
                              <td
                                v-if="
                                  asignacion.solicitud_tipo_servicio
                                    .tipo_servicio == 1 && remolque
                                "
                              >
                                <input
                                  type="text"
                                  class="form-control form-control-sm"
                                  v-model="
                                    form[indexCantVh][indexDias].remolque
                                  "
                                  :id="
                                    'slct_remolque_' +
                                      indexCantVh +
                                      '_' +
                                      indexDias
                                  "
                                  @change="
                                    buscarRemolques(indexCantVh, indexDias)
                                  "
                                  :disabled="
                                    hoy > form[indexCantVh][indexDias].fecha ||
                                      accion == 2 ||
                                      selectVehi !=
                                        `${indexCantVh}_${indexDias}` ||
                                      !form[indexCantVh][indexDias].remolque ||
                                      form[indexCantVh][indexDias].equipo ==
                                        null
                                  "
                                />
                              </td>
                              <!-- Campo Conductor / Operario Día -->
                              <td>
                                <input
                                  type="text"
                                  v-model="form[indexCantVh][indexDias].persona"
                                  :id="
                                    asignacion.solicitud_tipo_servicio
                                      .tipo_servicio == 1
                                      ? 'slct_conductor_' +
                                        indexCantVh +
                                        '_' +
                                        indexDias
                                      : 'slct_operario_' +
                                        indexCantVh +
                                        '_' +
                                        indexDias
                                  "
                                  class="form-control form-control-sm"
                                  :disabled="
                                    hoy > form[indexCantVh][indexDias].fecha ||
                                    asignacion.solicitud_tipo_servicio
                                      .tipo_remolque != null
                                      ? asignacion.solicitud_tipo_servicio
                                          .tipo_servicio == 1
                                        ? accion == 2 ||
                                          selectVehi !=
                                            `${indexCantVh}_${indexDias}` ||
                                          selectVehi !=
                                            `${indexCantVh}_${indexDias}` ||
                                          form[indexCantVh][indexDias].equipo ==
                                            null ||
                                          form[indexCantVh][indexDias]
                                            .remolque == null
                                        : accion == 2 ||
                                          selectVehi !=
                                            `${indexCantVh}_${indexDias}` ||
                                          selectVehi !=
                                            `${indexCantVh}_${indexDias}` ||
                                          form[indexCantVh][indexDias].equipo ==
                                            null
                                      : accion == 2 ||
                                        selectVehi !=
                                          `${indexCantVh}_${indexDias}` ||
                                        selectVehi !=
                                          `${indexCantVh}_${indexDias}` ||
                                        form[indexCantVh][indexDias].equipo ==
                                          null
                                  "
                                  @change="
                                    asignacion.solicitud_tipo_servicio
                                      .tipo_servicio == 1
                                      ? buscarConductores(
                                          indexCantVh,
                                          indexDias
                                        )
                                      : buscarOperarios(indexCantVh, indexDias)
                                  "
                                />
                                <small>{{
                                  form[indexCantVh][indexDias].persona_name
                                }}</small>
                              </td>
                              <!-- Campo Aparejador Día -->
                              <td
                                v-if="
                                  asignacion.solicitud_tipo_servicio
                                    .tipo_servicio == 2
                                "
                              >
                                <input
                                  type="text"
                                  class="form-control form-control-sm"
                                  v-model="
                                    form[indexCantVh][indexDias].aparejador
                                  "
                                  :id="
                                    'slct_aparejador_' +
                                      indexCantVh +
                                      '_' +
                                      indexDias
                                  "
                                  :disabled="
                                    hoy > form[indexCantVh][indexDias].fecha ||
                                      accion == 2 ||
                                      selectVehi !=
                                        `${indexCantVh}_${indexDias}` ||
                                      selectVehi !=
                                        `${indexCantVh}_${indexDias}` ||
                                      form[indexCantVh][indexDias].persona ==
                                        null
                                  "
                                  @change="
                                    buscarAparejadores(indexCantVh, indexDias)
                                  "
                                />
                                <small>{{
                                  form[indexCantVh][indexDias].aparejador_name
                                }}</small>
                              </td>
                              <!-- Campo Llegada Vehículo -->
                              <td>
                                <div class="row">
                                  <div class="col-6">
                                    <input
                                      type="date"
                                      v-model="
                                        form[indexCantVh][indexDias]
                                          .date_llegada_vh
                                      "
                                      placeholder="fecha_ini"
                                      label="Fecha Llegada"
                                      class="form-control form-control-sm"
                                      :disabled="
                                        accion == 2 ||
                                          selectVehi !=
                                            `${indexCantVh}_${indexDias}` ||
                                          hoy >
                                            form[indexCantVh][indexDias].fecha
                                      "
                                    />
                                  </div>
                                  <div class="col-6">
                                    <input
                                      type="text"
                                      v-model="
                                        form[indexCantVh][indexDias]
                                          .hora_llegada_vh
                                      "
                                      placeholder="hora_ini"
                                      label="Hora Llegada"
                                      class="form-control form-control-sm"
                                      v-mask="{
                                        mask: '99:99',
                                        hourFormat: '23',
                                      }"
                                      :disabled="
                                        accion == 2 ||
                                          selectVehi !=
                                            `${indexCantVh}_${indexDias}` ||
                                          hoy >
                                            form[indexCantVh][indexDias].fecha
                                      "
                                    />
                                  </div>
                                </div>
                              </td>
                              <!-- Campo Estado Día -->
                              <td class="text-center">
                                <span
                                  class="badge"
                                  v-if="form[indexCantVh][indexDias].estado"
                                  :class="
                                    form[indexCantVh][indexDias].estado == 1
                                      ? 'bg-lime'
                                      : form[indexCantVh][indexDias].estado == 2
                                      ? 'bg-success'
                                      : form[indexCantVh][indexDias].estado == 3
                                      ? 'bg-danger'
                                      : form[indexCantVh][indexDias].estado == 4
                                      ? 'bg-secondary'
                                      : 'bg-ligth'
                                  "
                                >
                                  {{ form[indexCantVh][indexDias].nEstado }}
                                </span>
                              </td>
                              <!-- Campo Acciones Día -->
                              <td class="text-center">
                                <div class="btn-group">
                                  <!-- Editar Postulación -->
                                  <button
                                    class="btn btn-sm bg-navy"
                                    v-if="
                                      hoy <=
                                        form[indexCantVh][indexDias].fecha &&
                                        accion == 1 &&
                                        (form[indexCantVh][indexDias].estado ==
                                          1 ||
                                          form[indexCantVh][indexDias].estado ==
                                            null) &&
                                        $store.getters.can(
                                          'cs.nacionalesPostulaciones.edit'
                                        ) &&
                                        (selectVehi !=
                                          `${indexCantVh}_${indexDias}` ||
                                          selectVehi == null)
                                    "
                                    @click="editDetPost(indexCantVh, indexDias)"
                                  >
                                    <i class="fas fa-edit"></i>
                                  </button>
                                  <!-- Guardar Postulación -->
                                  <button
                                    class="btn btn-sm bg-primary"
                                    v-if="
                                      hoy <=
                                        form[indexCantVh][indexDias].fecha &&
                                        accion == 1 &&
                                        $store.getters.can(
                                          'cs.nacionalesPostulaciones.save'
                                        ) &&
                                        selectVehi ==
                                          `${indexCantVh}_${indexDias}`
                                    "
                                    @click="save(indexCantVh, indexDias)"
                                  >
                                    <i class="fas fa-save"></i>
                                  </button>
                                  <button
                                    class="btn btn-sm bg-danger"
                                    @click="destroy(indexCantVh, indexDias)"
                                    v-if="
                                      accion == 1 &&
                                        [1, 2, 5].includes(
                                          asignacion.solicitud_tipo_servicio
                                            .solicitud.estado
                                        ) &&
                                        hoy <=
                                          form[indexCantVh][indexDias].fecha &&
                                        form[indexCantVh][indexDias].id &&
                                        $store.getters.can(
                                          'cs.nacionalesPostulaciones.delete'
                                        ) &&
                                        selectVehi !=
                                          `${indexCantVh}_${indexDias}`
                                    "
                                  >
                                    <i class="fas fa-trash"></i>
                                  </button>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-footer">
                <div class="row">
                  <button class="btn bg-secondary col-md-1" @click="back()">
                    <i class="fas fa-reply"></i><br />Volver
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Loading from "../../../../components/Loading";
import moment from "moment";

export default {
  name: "PostulacionForm",
  components: {
    Loading,
  },
  data() {
    return {
      cargando: false,
      accion: 2,
      asignacion: {},
      solicitud: {},
      form: [],
      selectVehi: null,
      remolque: false,
      cantPostulados: [],
      hoy: moment().format("YYYY-MM-DD"),
      listasForms: {
        date_llegada_vh: [],
        hora_llegada_vh: [],
        equipo: [],
        remolque: [],
        persona: [],
        persona2: [],
        persona_name: [],
        persona_name2: [],
        operario: [],
      },
    };
  },

  methods: {
    async init() {
      let me = this;
      me.cargando = true;
      this.selectVehi = null;
      me.accion = me.$route.params.accion;
      await axios
        .get(
          "/api/cs/postulacionesNacionales/show/" +
            me.$route.params.asignacion_id
        )
        .then(async (response) => {
          me.cargando = false;
          me.asignacion = response.data;
          this.remolque = me.asignacion.solicitud_tipo_servicio.tipo_remolque
            ? true
            : false;
          me.solicitud = me.asignacion
            ? me.asignacion.solicitud_tipo_servicio.solicitud
            : {};
          await this.arrayForm();
        })
        .catch(function(error) {
          me.cargando = false;
          me.$swal({
            icon: "error",
            title: "Ocurrió un error: " + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    arrayForm() {
      let arrayPost = [];
      this.listasForms = {
        date_llegada_vh: [],
        hora_llegada_vh: [],
        equipo: [],
        equipo_placa: [],
        equipo_serial: [],
        remolque: [],
        persona: [],
        persona_name: [],
        operario: [],
        aparejador: [],
        aparejador_name: [],
      };
      for (
        let cantVh = 1;
        cantVh <= this.asignacion.cantidad_vehiculos;
        cantVh++
      ) {
        let arrayDias = [];
        let cant_postulados = 0;

        for (
          let indiceServ = 1;
          indiceServ <= this.asignacion.cantidad_servicios;
          indiceServ++
        ) {
          let form = {
            id: null,
            equipo_type:
              this.asignacion.solicitud_tipo_servicio.tipo_servicio == 1
                ? "App\\Vehiculo"
                : "App\\Equipo",
            equipo_id: null,
            equipo: null,
            equipo_placa: null,
            equipo_serial: null,
            cs_asignacion_nacional_id: this.asignacion.id,
            cs_solicitud_nacional_tipo_serv_id: this.asignacion
              .cs_solicitud_nacional_tipo_serv_id,
            date_llegada_vh: null,
            hora_llegada_vh: null,
            persona_type:
              this.asignacion.solicitud_tipo_servicio.tipo_servicio == 1
                ? "App\\Conductor"
                : "App\\Operario",
            persona_id: null,
            persona: null,
            persona_name: null,
            remolque_id: null,
            remolque: null,
            aplicaRemol: this.remolque,
            aparejador_id: null,
            aparejador: null,
            aparejador_name: null,
            fecha: moment(this.asignacion.fecha_inicial)
              .add(indiceServ - 1, "days")
              .format("YYYY-MM-DD"),
            equipo_indice: cantVh,
            pos_dia: indiceServ,
            estado: null,
          };

          let post = null;
          if (this.asignacion.postulaciones.length > 0) {
            post = this.asignacion.postulaciones.find(
              (post) =>
                post.equipo_indice == cantVh && post.pos_dia == indiceServ
            );
          }

          if (post) {
            cant_postulados++;
            let equipoInfo = post.equipo
              ? post.equipo_type === "App\\Vehiculo"
                ? post.equipo.placa
                : `${post.equipo.placa} ${post.equipo.serial}`
              : "No hay información de equipo";
            form = {
              id: post.id,
              equipo_type: post.equipo_type,
              equipo_id: post.equipo_id ? post.equipo_id : null,
              equipo: equipoInfo,
              equipo_placa: post.equipo ? post.equipo.placa : null,
              equipo_serial:
                post.equipo && post.equipo.serial ? post.equipo.serial : null,
              cs_asignacion_nacional_id: post.cs_asignacion_nacional_id,
              cs_solicitud_nacional_tipo_serv_id:
                post.cs_solicitud_nacional_tipo_serv_id,
              date_llegada_vh: post.fecha_llegada_vh
                ? post.fecha_llegada_vh.slice(0, 10)
                : null,
              hora_llegada_vh: post.fecha_llegada_vh
                ? post.fecha_llegada_vh.slice(11, 19)
                : null,
              persona_type: post.persona_type,
              persona_id: post.persona_id,
              persona: post.persona ? post.persona.numero_documento : null,
              persona_name: post.persona
                ? `${post.persona.nombres} ${post.persona.apellidos}`
                : null,
              remolque_id: post.remolque_id,
              remolque: post.remolque ? post.remolque.placa : null,
              aplicaRemol: this.remolque,
              aparejador_id: post.aparejador_id,
              aparejador: post.aparejador
                ? post.aparejador.numero_documento
                : null,
              aparejador_name: post.aparejador
                ? `${post.aparejador.nombres} ${post.aparejador.apellidos}`
                : null,
              fecha: moment(this.asignacion.fecha_inicial)
                .add(indiceServ - 1, "days")
                .format("YYYY-MM-DD"),
              equipo_indice: post.equipo_indice,
              pos_dia: post.pos_dia,
              estado: post.estado,
              nEstado: post.nEstado,
            };
          }
          arrayDias.push(form);
          this.cantPostulados.push(cant_postulados);
        }
        arrayPost.push(arrayDias);
      }
      this.form = arrayPost;
    },

    async buscarVehiculos(indexCantVh, indexDias) {
      this.cargando = true;
      let dato = null;
      let veh = null;
      let fecha = null;
      let fecha_ini = null;
      let fecha_fin = null;
      if (indexDias != null) {
        dato = this.form[indexCantVh][indexDias].equipo;
        fecha = this.form[indexCantVh][indexDias].fecha;
      } else {
        this.selectVehi = null;
        dato = this.listasForms.equipo[indexCantVh];
        fecha_ini = this.asignacion.fecha_inicial;
        fecha_fin = moment(this.asignacion.fecha_inicial)
          .add(this.asignacion.cantidad_servicios, "days")
          .format("YYYY-MM-DD");
      }

      if (dato) {
        let res = await this.validarStatus(
          dato,
          "V",
          fecha,
          fecha_ini,
          fecha_fin
        );
        if (res.data.length > 0) {
          veh = res["data"][0];
          //Valida el tipo de vehiculo
          if (
            res["data"][0].tipo_vehiculo_id !=
            this.asignacion.solicitud_tipo_servicio.tipo_vh_equi
          ) {
            if (indexDias != null) {
              this.form[indexCantVh][indexDias].equipo = null;
            } else {
              this.listasForms.equipo[indexCantVh] = null;
            }
            this.cargando = false;
            await this.$swal({
              icon: "error",
              text: `El tipo de vehículo no coincide.`,
              confirmButtonText: "Aceptar!",
            });
            this.selectAllRemolq(indexCantVh, indexDias, null);
            this.selectAllVehi(indexCantVh, indexDias, null);
            return false;
          }

          // Valida la documentación del vehículo
          if (res.validacion.error) {
            this.cargando = false;
            await this.msgDocumentacion(res);
            if (
              res.validacion.error.msg ||
              (res.validacion.error.documentos &&
                res.validacion.error.documentos.obligatorios !== undefined) ||
              (res.validacion.error.documentos &&
                res.validacion.error.documentos.vencidos !== undefined) ||
              (res.validacion.error.documentos &&
                res.validacion.error.documentos.no_verificados !== undefined)
            ) {
              if (indexDias != null) {
                this.form[indexCantVh][indexDias].equipo = null;
              } else {
                this.listasForms.equipo[indexCantVh] = null;
                this.selectAllRemolq(indexCantVh, indexDias, null);
                this.selectAllVehi(indexCantVh, indexDias, null);
              }
            }
            return false;
          }

          //Valida la información del vehículo
          if (res["info"] == false) {
            this.cargando = false;
            await this.$swal({
              text:
                "Hacen falta campos por diligenciar en el formulario de creación de Vehículos..",
              icon: "error",
              confirmButtonText: "Aceptar!",
            });
            if (indexDias != null) {
              this.form[indexCantVh][indexDias].equipo = null;
            } else {
              this.listasForms.equipo[indexCantVh] = null;
            }
            this.selectAllRemolq(indexCantVh, indexDias, null);
            this.selectAllVehi(indexCantVh, indexDias, null);
            return false;
          }

          //Valida la línea de negocio del vehículo
          if (!res["has_lineas"]) {
            this.$swal({
              text: `El vehículo no se encuentra en la línea de negocio Carga Seca`,
              icon: "error",
              confirmButtonText: "Aceptar!",
            });
            if (indexDias != null) {
              this.form[indexCantVh][indexDias].equipo = null;
            } else {
              this.listasForms.equipo[indexCantVh] = null;
            }
            this.selectAllRemolq(indexCantVh, indexDias, null);
            this.selectAllVehi(indexCantVh, indexDias, null);
            this.cargando = false;
            return false;
          }

          //Valida la inspeccion del vehiculo
          /* if (res["inspeccion"] != null) {
            if (res.inspeccion.estado == 4) {
              this.cargando = false;
              await this.$swal({
                text: `La inspeccion del vehículo se encuentra en estado rechazado`,
                icon: "error",
                confirmButtonText: "Aceptar!",
              });

              if (indexDias != null) {
                this.form[indexCantVh][indexDias].equipo = null;
              } else {
                this.listasForms.equipo[indexCantVh] = null;
              }
              this.selectAllRemolq(indexCantVh, indexDias, null);
              this.selectAllVehi(indexCantVh, indexDias, null);

              return false;
            } else if (res.inspeccion.estado == 5) {
              this.cargando = false;
              await this.$swal({
                text: `La inspeccion del vehículo se encuentra aprobada con novedad`,
                icon: "success",
                confirmButtonText: "Aceptar!",
              });
            } else if (res.inspeccion.estado == 2) {
              this.cargando = false;
              await this.$swal({
                text: `La inspeccion del vehículo se encuentra aprobada`,
                icon: "success",
                confirmButtonText: "Aceptar!",
              });
            } else if (res.inspeccion.estado == 3) {
              this.cargando = false;
              await this.$swal({
                text: `La inspeccion del vehículo se encuentra autorizada`,
                icon: "success",
                confirmButtonText: "Aceptar!",
              });
            }
          } else {
            this.cargando = false;
            await this.$swal({
              text: `El vehículo no cuenta con inspección vigente`,
              icon: "error",
              confirmButtonText: "Aceptar!",
            });
            if (indexDias != null) {
              this.form[indexCantVh][indexDias].equipo = null;
            } else {
              this.listasForms.equipo[indexCantVh] = null;
            }
            this.selectAllRemolq(indexCantVh, indexDias, null);
            this.selectAllVehi(indexCantVh, indexDias, null);
            return false;
          } */
          //Valida las postulaciones del vehículo
          if (res.postulacion) {
            if (indexDias != null) {
              this.form[indexCantVh][indexDias].equipo = null;
            } else {
              this.listasForms.equipo[indexCantVh] = null;
            }
            this.cargando = false;
            await this.$swal({
              text: `El vehículo tiene postulaciones activas en otra solicitud.`,
              icon: "error",
              confirmButtonText: "Aceptar!",
            });
            this.selectAllRemolq(indexCantVh, indexDias, null);
            this.selectAllVehi(indexCantVh, indexDias, null);
            return false;
          }
        } else {
          this.listasForms.remolque[indexCantVh] = null;
          this.cargando = false;
          await this.$swal({
            text:
              "El vehículo digitado NO se encuentra registrado, pruebe con otra placa.",
            icon: "error",
            confirmButtonText: "Aceptar!",
          });
          if (indexDias != null) {
            this.form[indexCantVh][indexDias].equipo = null;
          } else {
            this.listasForms.equipo[indexCantVh] = null;
            this.selectAllRemolq(indexCantVh, indexDias, null);
            this.selectAllVehi(indexCantVh, indexDias, null);
          }
          return false;
        }
        this.cargando = false;
        this.$swal({
          text:
            "El vehículo cumple con los requisitos para poder ser postulado...",
          icon: "success",
          confirmButtonText: "Aceptar!",
        });
      }
      this.selectAllRemolq(indexCantVh, indexDias, null);
      this.selectAllVehi(indexCantVh, indexDias, veh);
      this.cargando = false;
    },

    async selectAllVehi(indexCantVh, indexDias, vehiculo) {
      if (indexDias != null) {
        this.form[indexCantVh][indexDias].equipo = vehiculo
          ? vehiculo.placa
          : null;
        this.form[indexCantVh][indexDias].equipo_id = vehiculo
          ? vehiculo.id
          : null;
        this.form[indexCantVh][indexDias].aplicaRemol = this.remolque;
      } else {
        // Se valida si el vehículo es un cabezote
        this.listasForms.remolque[indexCantVh] =
          this.remolque && vehiculo
            ? this.listasForms.remolque[indexCantVh]
            : null;

        // Se asignan los valores al formulario
        for (let i = 0; i < this.asignacion.cantidad_servicios; i++) {
          if (
            this.hoy <=
              moment(this.form[indexCantVh][i].fecha).format("YYYY-MM-DD") &&
            !this.form[indexCantVh][i].id
          ) {
            this.form[indexCantVh][i].equipo = vehiculo ? vehiculo.placa : null;
            this.form[indexCantVh][i].equipo_id = vehiculo ? vehiculo.id : null;
          }
        }
      }
    },

    async buscarEquipos(indexCantVh, indexDias) {
      this.cargando = true;
      this.remolque = false;
      let dato = null;
      let equi = null;
      let fecha = null;
      let fecha_ini = null;
      let fecha_fin = null;
      if (indexDias != null) {
        dato = this.form[indexCantVh][indexDias].equipo;
        fecha = this.form[indexCantVh][indexDias].fecha;
      } else {
        this.selectVehi = null;
        dato = this.listasForms.equipo[indexCantVh];
        fecha_ini = this.asignacion.fecha_inicial;
        fecha_fin = moment(this.asignacion.fecha_inicial)
          .add(this.asignacion.cantidad_servicios, "days")
          .format("YYYY-MM-DD");
      }

      if (dato) {
        let res = await this.validarStatus(
          dato,
          "E",
          fecha,
          fecha_ini,
          fecha_fin
        );

        if (res.data.length > 0) {
          equi = res["data"][0];
          if (
            res["data"][0].tipo_equipo !=
            this.asignacion.solicitud_tipo_servicio.tipo_vh_equi
          ) {
            if (indexDias != null) {
              this.form[indexCantVh][indexDias].equipo = null;
            } else {
              this.listasForms.equipo[indexCantVh] = null;
            }
            this.cargando = false;
            await this.$swal({
              icon: "error",
              text: `El tipo de equipo no coincide.`,
              confirmButtonText: "Aceptar!",
            });
            this.selectAllEqui(indexCantVh, indexDias, null);
            return false;
          }
          //Valida la documentación del equipo
          if (res.validacion.error) {
            this.cargando = false;
            await this.msgDocumentacion(res);
            if (
              res.validacion.error.msg ||
              (res.validacion.error.documentos &&
                res.validacion.error.documentos.obligatorios !== undefined) ||
              (res.validacion.error.documentos &&
                res.validacion.error.documentos.vencidos !== undefined) ||
              (res.validacion.error.documentos &&
                res.validacion.error.documentos.no_verificados !== undefined)
            ) {
              if (indexDias != null) {
                this.form[indexCantVh][indexDias].equipo = null;
              } else {
                this.listasForms.equipo[indexCantVh] = null;
                this.selectAllEqui(indexCantVh, indexDias, null);
              }
            }
            return false;
          }

          //Valida la información del equipo
          if (res["info"] == false) {
            this.cargando = false;
            await this.$swal({
              icon: "error",
              text: `Hacen falta campos por diligenciar en el formulario de creación de Equipos`,
              confirmButtonText: "Aceptar!",
            });
            if (indexDias != null) {
              this.form[indexCantVh][indexDias].equipo = null;
            } else {
              this.listasForms.equipo[indexCantVh] = null;
            }
            this.selectAllEqui(indexCantVh, indexDias, null);
            return false;
          }

          //Valida la línea de negocio del equipo
          if (!res["has_lineas"]) {
            this.cargando = false;
            await this.$swal({
              icon: "error",
              text: `El equipo no se encuentra en la línea de negocio Carga Seca`,
              confirmButtonText: "Aceptar!",
            });
            if (indexDias != null) {
              this.form[indexCantVh][indexDias].equipo = null;
            } else {
              this.listasForms.equipo[indexCantVh] = null;
            }
            this.selectAllEqui(indexCantVh, indexDias, null);
            return false;
          }

          //Valida las postulaciones del equipo
          if (res.postulacion) {
            if (indexDias != null) {
              this.form[indexCantVh][indexDias].equipo = null;
            } else {
              this.listasForms.equipo[indexCantVh] = null;
            }
            this.cargando = false;
            await this.$swal({
              icon: "error",
              text: `El equipo tiene postulaciones activas en otra solicitud.`,
              confirmButtonText: "Aceptar!",
            });
            this.selectAllEqui(indexCantVh, indexDias, null);
            return false;
          }
        } else {
          this.cargando = false;
          await this.$swal({
            icon: "error",
            text:
              "El equipo digitado NO se encuentra registrado, pruebe con otra placa o serial.",
            confirmButtonText: "Aceptar!",
          });
          if (indexDias != null) {
            this.form[indexCantVh][indexDias].equipo = null;
          } else {
            this.listasForms.equipo[indexCantVh] = null;
            this.selectAllEqui(indexCantVh, indexDias, null);
          }
          return false;
        }
        this.cargando = false;
        await this.$swal({
          icon: "success",
          text:
            "El equipo cumple con los requisitos para poder ser postulado...",
          confirmButtonText: "Aceptar!",
        });
      }
      this.selectAllEqui(indexCantVh, indexDias, equi);
      this.cargando = false;
    },

    async selectAllEqui(indexCantVh, indexDias, equipo) {
      if (indexDias != null) {
        this.form[indexCantVh][indexDias].equipo = equipo
          ? `${equipo.placa} ${equipo.serial}`
          : null;
        this.form[indexCantVh][indexDias].equipo_placa = equipo
          ? equipo.placa
          : null;
        this.form[indexCantVh][indexDias].equipo_serial = equipo
          ? equipo.serial
          : null;
        this.form[indexCantVh][indexDias].equipo_id = equipo ? equipo.id : null;
      } else {
        this.listasForms.equipo[indexCantVh] = equipo
          ? `${equipo.placa} ${equipo.serial}`
          : null;
        this.listasForms.equipo_placa[indexCantVh] = equipo
          ? equipo.placa
          : null;
        this.listasForms.equipo_serial[indexCantVh] = equipo
          ? equipo.serial
          : null;

        // Se asignan los valores al formulario
        for (let i = 0; i < this.asignacion.cantidad_servicios; i++) {
          if (
            this.hoy <=
              moment(this.form[indexCantVh][i].fecha).format("YYYY-MM-DD") &&
            !this.form[indexCantVh][i].id
          ) {
            this.form[indexCantVh][i].equipo = equipo
              ? `${equipo.placa} ${equipo.serial}`
              : null;
            this.form[indexCantVh][i].equipo_placa = equipo
              ? equipo.placa
              : null;
            this.form[indexCantVh][i].equipo_serial = equipo
              ? equipo.serial
              : null;
            this.form[indexCantVh][i].equipo_id = equipo ? equipo.id : null;
          }
        }
      }
    },

    async buscarRemolques(indexCantVh, indexDias) {
      this.cargando = true;
      let dato = null;
      let rem = null;
      let fecha = null;
      let fecha_ini = null;
      let fecha_fin = null;
      if (indexDias != null) {
        dato = this.form[indexCantVh][indexDias].remolque;
        fecha = this.form[indexCantVh][indexDias].fecha;
      } else {
        this.selectVehi = null;
        dato = this.listasForms.remolque[indexCantVh];
        fecha_ini = this.asignacion.fecha_inicial;
        fecha_fin = moment(this.asignacion.fecha_inicial)
          .add(this.asignacion.cantidad_servicios, "days")
          .format("YYYY-MM-DD");
      }

      if (dato) {
        let res = await this.validarStatus(
          dato,
          "R",
          fecha,
          fecha_ini,
          fecha_fin
        );

        if (res.data.length > 0) {
          rem = res["data"][0];

          //Valida la documentación del remolque
          if (res.validacion.error) {
            this.cargando = false;
            if (
              res.validacion.error.msg ||
              (res.validacion.error.documentos &&
                res.validacion.error.documentos.obligatorios !== undefined) ||
              (res.validacion.error.documentos &&
                res.validacion.error.documentos.vencidos !== undefined) ||
              (res.validacion.error.documentos &&
                res.validacion.error.documentos.no_verificados !== undefined)
            ) {
              if (indexDias != null) {
                this.form[indexCantVh][indexDias].remolque = null;
              } else {
                this.listasForms.remolque[indexCantVh] = null;
                this.selectAllRemolq(indexCantVh, indexDias, null);
              }
            }
            await this.msgDocumentacion(res);
            return false;
          }

          //Valida la información del remolque
          if (res["info"] == false) {
            this.cargando = false;
            await this.$swal({
              icon: "error",
              text: `Hacen falta campos por diligenciar en el formulario de creación de Remolques`,
              confirmButtonText: "Aceptar!",
            });
            if (indexDias != null) {
              this.form[indexCantVh][indexDias].remolque = null;
            } else {
              this.listasForms.remolque[indexCantVh] = null;
              this.selectAllRemolq(indexCantVh, indexDias, null);
            }
            return false;
          }

          //Valida la línea de negocio del remolque
          if (!res["has_lineas"]) {
            this.cargando = false;
            await this.$swal({
              icon: "error",
              text: `El remolque no se encuentra en la línea de negocio Carga Seca`,
              confirmButtonText: "Aceptar!",
            });
            if (indexDias != null) {
              this.form[indexCantVh][indexDias].remolque = null;
            } else {
              this.listasForms.remolque[indexCantVh] = null;
              this.selectAllRemolq(indexCantVh, indexDias, null);
            }
            return false;
          }

          //Valida las postulaciones del remolque
          if (res.postulacion) {
            if (indexDias != null) {
              this.form[indexCantVh][indexDias].remolque = null;
            } else {
              this.listasForms.remolque[indexCantVh] = null;
              this.selectAllRemolq(indexCantVh, indexDias, null);
            }
            this.cargando = false;
            await this.$swal({
              icon: "error",
              text: `El remolque tiene postulaciones activas en otra solicitud.`,
              confirmButtonText: "Aceptar!",
            });
            return false;
          }
        } else {
          this.listasForms.remolque[indexCantVh] = null;
          this.cargando = false;
          this.$swal({
            icon: "error",
            text:
              "El remolque digitado NO se encuentra registrado, pruebe con otra placa.",
            confirmButtonText: "Aceptar!",
          });
          if (indexDias != null) {
            this.form[indexCantVh][indexDias].remolque = null;
          } else {
            this.listasForms.remolque[indexCantVh] = null;
            this.selectAllRemolq(indexCantVh, indexDias, null);
          }
          return false;
        }
        this.cargando = false;
        await this.$swal({
          icon: "success",
          text:
            "El remolque cumple con los requisitos para poder ser postulado...",
          confirmButtonText: "Aceptar!",
        });
      }
      this.selectAllRemolq(indexCantVh, indexDias, rem);
      this.cargando = false;
    },

    selectAllRemolq(indexCantVh, indexDias, remolque) {
      if (indexDias != null) {
        this.form[indexCantVh][indexDias].remolque = remolque
          ? remolque.placa
          : null;
        this.form[indexCantVh][indexDias].remolque_id = remolque
          ? remolque.id
          : null;
      } else {
        // Se asignan los valores al formulario
        for (let i = 0; i < this.asignacion.cantidad_servicios; i++) {
          if (
            this.hoy <=
              moment(this.form[indexCantVh][i].fecha).format("YYYY-MM-DD") &&
            !this.form[indexCantVh][i].id
          ) {
            this.form[indexCantVh][i].remolque = remolque
              ? remolque.placa
              : null;
            this.form[indexCantVh][i].remolque_id = remolque
              ? remolque.id
              : null;
          }
        }
      }
    },

    async buscarConductores(indexCantVh, indexDias) {
      this.cargando = true;
      let dato = null;
      let cond = null;
      let fecha = null;
      let fecha_ini = null;
      let fecha_fin = null;
      if (indexDias != null) {
        dato = this.form[indexCantVh][indexDias].persona;
        fecha = this.form[indexCantVh][indexDias].fecha;
      } else {
        this.selectVehi = null;
        dato = this.listasForms.persona[indexCantVh];
        fecha_ini = this.asignacion.fecha_inicial;
        fecha_fin = moment(this.asignacion.fecha_inicial)
          .add(this.asignacion.cantidad_servicios, "days")
          .format("YYYY-MM-DD");
      }

      if (dato) {
        let res = await this.validarStatus(
          dato,
          "C",
          fecha,
          fecha_ini,
          fecha_fin
        );

        if (res.data.length > 0) {
          cond = res["data"][0];

          //Valida la documentación de la persona
          if (res.validacion.error) {
            this.cargando = false;
            await this.msgDocumentacion(res);
            if (
              res.validacion.error.documentos.obligatorios !== undefined ||
              res.validacion.error.documentos.vencidos !== undefined ||
              res.validacion.error.documentos.no_verificados !== undefined
            ) {
              if (indexDias != null) {
                this.form[indexCantVh][indexDias].persona = null;
              } else {
                this.listasForms.persona[indexCantVh] = null;
              }
              this.selectAllPersonas(indexCantVh, indexDias, null);
              return false;
            }
          }

          //Valida la información de la persona
          if (res["info"] == false) {
            this.cargando = false;
            await this.$swal({
              icon: "error",
              text: `Hacen falta campos por diligenciar en el formulario de creación de Conductores`,
              confirmButtonText: "Aceptar!",
            });
            if (indexDias != null) {
              this.form[indexCantVh][indexDias].persona = null;
            } else {
              this.listasForms.persona[indexCantVh] = null;
            }
            this.selectAllPersonas(indexCantVh, indexDias, null);
            return false;
          }

          //Valida la línea de negocio de la persona
          if (!res["has_lineas"]) {
            this.cargando = false;
            await this.$swal({
              icon: "error",
              text: `El conductor no se encuentra en la línea de negocio Carga Seca`,
              confirmButtonText: "Aceptar!",
            });
            if (indexDias != null) {
              this.form[indexCantVh][indexDias].persona = null;
            } else {
              this.listasForms.persona[indexCantVh] = null;
            }
            this.selectAllPersonas(indexCantVh, indexDias, null);
            return false;
          }

          //Valida las postulaciones de la persona
          if (res.postulacion) {
            if (indexDias != null) {
              this.form[indexCantVh][indexDias].persona = null;
            } else {
              this.listasForms.persona[indexCantVh] = null;
            }
            this.cargando = false;
            await this.$swal({
              icon: "error",
              text: `El conductor tiene postulaciones activas en otra solicitud.`,
              confirmButtonText: "Aceptar!",
            });
            this.selectAllPersonas(indexCantVh, indexDias, null);
            return false;
          }
        } else {
          this.listasForms.persona[indexCantVh] = null;
          this.cargando = false;
          this.$swal({
            icon: "error",
            text:
              "El conductor digitado NO se encuentra registrado, pruebe con otro documento.",
            confirmButtonText: "Aceptar!",
          });
          if (indexDias != null) {
            this.form[indexCantVh][indexDias].persona = null;
          } else {
            this.listasForms.persona[indexCantVh] = null;
          }

          this.selectAllPersonas(indexCantVh, indexDias, null);
          return false;
        }
        this.cargando = false;
        await this.$swal({
          icon: "success",
          text:
            "El conductor cumple con los requisitos para poder ser postulado...",
          confirmButtonText: "Aceptar!",
        });
      }

      this.selectAllPersonas(indexCantVh, indexDias, cond);
      this.cargando = false;
    },

    async buscarOperarios(indexCantVh, indexDias) {
      this.cargando = true;
      let dato = null;
      let oper = null;
      let fecha = null;
      let fecha_ini = null;
      let fecha_fin = null;
      if (indexDias != null) {
        dato = this.form[indexCantVh][indexDias].persona;
        fecha = this.form[indexCantVh][indexDias].fecha;
      } else {
        this.selectVehi = null;
        dato = this.listasForms.persona[indexCantVh];
        fecha_ini = this.asignacion.fecha_inicial;
        fecha_fin = moment(this.asignacion.fecha_inicial)
          .add(this.asignacion.cantidad_servicios, "days")
          .format("YYYY-MM-DD");
      }

      if (dato) {
        let res = await this.validarStatus(
          dato,
          "O",
          fecha,
          fecha_ini,
          fecha_fin,
          1
        );

        if (res.data.length > 0) {
          oper = res["data"][0];

          //Valida la documentación del operario
          if (res.validacion.error) {
            this.cargando = false;
            await this.msgDocumentacion(res);
            if (
              res.validacion.error.documentos.obligatorios !== undefined ||
              res.validacion.error.documentos.vencidos !== undefined ||
              res.validacion.error.documentos.no_verificados !== undefined
            ) {
              if (indexDias != null) {
                this.form[indexCantVh][indexDias].persona = null;
              } else {
                this.listasForms.persona[indexCantVh] = null;
              }
              this.selectAllPersonas(indexCantVh, indexDias, null);
              return false;
            }
          }

          // //Valida la información del operario
          // if (res["info"] == false) {
          //   this.$swal({
          //     icon: "error",
          //     text: `Hacen falta campos por diligenciar en el formulario de creación de Operarios`,
          //     toast: true,
          //     position: "top-end",
          //     showConfirmButton: false,
          //     timer: 3000,
          //     timerProgressBar: true,
          //   });
          //   if (indexDias != null) {
          //     this.form[indexCantVh][indexDias].persona = null;
          //   } else {
          //     this.listasForms.persona[indexCantVh] = null;
          //   }
          //   this.selectAllPersonas(indexCantVh, indexDias, null);
          //   this.cargando = false;
          //   return false;
          // }

          //Valida la línea de negocio del operario
          if (!res["has_lineas"]) {
            this.cargando = false;
            await this.$swal({
              icon: "error",
              text: `El operario no se encuentra en la línea de negocio Carga Seca`,
              confirmButtonText: "Aceptar!",
            });
            if (indexDias != null) {
              this.form[indexCantVh][indexDias].persona = null;
            } else {
              this.listasForms.persona[indexCantVh] = null;
            }
            this.selectAllPersonas(indexCantVh, indexDias, null);
            return false;
          }

          //Valida las postulaciones del operario
          if (res.postulacion) {
            if (indexDias != null) {
              this.form[indexCantVh][indexDias].persona = null;
            } else {
              this.listasForms.persona[indexCantVh] = null;
            }
            this.cargando = false;
            await this.$swal({
              icon: "error",
              text: `El operario tiene postulaciones activas en otra solicitud.`,
              confirmButtonText: "Aceptar!",
            });
            this.selectAllPersonas(indexCantVh, indexDias, null);
            return false;
          }
        } else {
          this.listasForms.persona[indexCantVh] = null;
          this.cargando = false;
          this.$swal({
            icon: "error",
            text:
              "El operario digitado NO se encuentra registrado, pruebe con otro documento.",
            confirmButtonText: "Aceptar!",
          });
          if (indexDias != null) {
            this.form[indexCantVh][indexDias].persona = null;
          } else {
            this.listasForms.persona[indexCantVh] = null;
          }

          this.selectAllPersonas(indexCantVh, indexDias, null);
          return false;
        }

        this.cargando = false;
        await this.$swal({
          icon: "success",
          text:
            "El operario cumple con los requisitos para poder ser postulado...",
          confirmButtonText: "Aceptar!",
        });
      }
      this.cargando = false;
      this.selectAllPersonas(indexCantVh, indexDias, oper);
    },

    selectAllPersonas(indexCantVh, indexDias, persona) {
      if (indexDias != null) {
        this.form[indexCantVh][indexDias].persona = persona
          ? persona.numero_documento
          : null;
        this.form[indexCantVh][indexDias].persona_name = persona
          ? `${persona.nombres} ${persona.apellidos}`
          : null;
        this.form[indexCantVh][indexDias].persona_id = persona
          ? persona.id
          : null;
      } else {
        // Se asignan los valores al formulario
        for (let i = 0; i < this.asignacion.cantidad_servicios; i++) {
          if (
            this.hoy <=
              moment(this.form[indexCantVh][i].fecha).format("YYYY-MM-DD") &&
            !this.form[indexCantVh][i].id
          ) {
            this.listasForms.persona[indexCantVh] = persona
              ? persona.numero_documento
              : null;
            this.listasForms.persona_name[indexCantVh] = persona
              ? `${persona.nombres} ${persona.apellidos}`
              : null;
            this.form[indexCantVh][i].persona = persona
              ? persona.numero_documento
              : null;
            this.form[indexCantVh][i].persona_name = persona
              ? `${persona.nombres} ${persona.apellidos}`
              : null;
            this.form[indexCantVh][i].persona_id = persona ? persona.id : null;
          }
        }
      }
    },

    async buscarAparejadores(indexCantVh, indexDias) {
      this.cargando = true;
      let dato = null;
      let apar = null;
      let fecha = null;
      let fecha_ini = null;
      let fecha_fin = null;
      if (indexDias != null) {
        dato = this.form[indexCantVh][indexDias].aparejador;
        fecha = this.form[indexCantVh][indexDias].fecha;
      } else {
        this.selectVehi = null;
        dato = this.listasForms.aparejador[indexCantVh];
        fecha_ini = this.asignacion.fecha_inicial;
        fecha_fin = moment(this.asignacion.fecha_inicial)
          .add(this.asignacion.cantidad_servicios, "days")
          .format("YYYY-MM-DD");
      }

      if (dato) {
        let res = await this.validarStatus(
          dato,
          "O",
          fecha,
          fecha_ini,
          fecha_fin,
          2
        );

        if (res.data.length > 0) {
          apar = res["data"][0];

          //Valida la documentación del aparejador
          if (res.validacion.error) {
            this.cargando = false;
            await this.msgDocumentacion(res);
            if (
              res.validacion.error.documentos.obligatorios !== undefined ||
              res.validacion.error.documentos.vencidos !== undefined ||
              res.validacion.error.documentos.no_verificados !== undefined
            ) {
              if (indexDias != null) {
                this.form[indexCantVh][indexDias].aparejador = null;
              } else {
                this.listasForms.aparejador[indexCantVh] = null;
              }
              this.selectAllApar(indexCantVh, indexDias, null);
              return false;
            }
          }

          // //Valida la información del aparejador
          // if (res["info"] == false) {
          //   this.$swal({
          //     icon: "error",
          //     text: `Hacen falta campos por diligenciar en el formulario de creación de Aparejadores`,
          //     toast: true,
          //     position: "top-end",
          //     showConfirmButton: false,
          //     timer: 3000,
          //     timerProgressBar: true,
          //   });
          //   if (indexDias != null) {
          //     this.form[indexCantVh][indexDias].aparejador = null;
          //   } else {
          //     this.listasForms.aparejador[indexCantVh] = null;
          //   }
          //   this.selectAllApar(indexCantVh, indexDias, null);
          //   this.cargando = false;
          //   return false;
          // }

          //Valida la línea de negocio del aparejador
          if (!res["has_lineas"]) {
            await this.$swal({
              icon: "error",
              text: `El aparejador no se encuentra en la línea de negocio Carga Seca`,
              confirmButtonText: "Aceptar!",
            });
            if (indexDias != null) {
              this.form[indexCantVh][indexDias].aparejador = null;
            } else {
              this.listasForms.aparejador[indexCantVh] = null;
            }
            this.selectAllApar(indexCantVh, indexDias, null);
            this.cargando = false;
            return false;
          }

          //Valida las postulaciones del aparejador
          if (res.postulacion) {
            if (indexDias != null) {
              this.form[indexCantVh][indexDias].aparejador = null;
            } else {
              this.listasForms.aparejador[indexCantVh] = null;
            }
            await this.$swal({
              icon: "error",
              text: `El aparejador tiene postulaciones activas en otra solicitud.`,
              confirmButtonText: "Aceptar!",
            });
            this.selectAllApar(indexCantVh, indexDias, null);
            this.cargando = false;
            return false;
          }
        } else {
          this.listasForms.aparejador[indexCantVh] = null;
          this.$swal({
            icon: "error",
            text:
              "El aparejador digitado NO se encuentra registrado, pruebe con otro documento.",
            confirmButtonText: "Aceptar!",
          });
          this.cargando = false;
          if (indexDias != null) {
            this.form[indexCantVh][indexDias].aparejador = null;
          } else {
            this.listasForms.aparejador[indexCantVh] = null;
          }

          this.selectAllApar(indexCantVh, indexDias, null);
          return false;
        }

        this.$swal({
          icon: "success",
          text:
            "El aparejador cumple con los requisitos para poder ser postulado...",
          confirmButtonText: "Aceptar!",
        });
      }

      this.selectAllApar(indexCantVh, indexDias, apar);
      this.cargando = false;
    },

    selectAllApar(indexCantVh, indexDias, aparejador) {
      if (indexDias != null) {
        this.form[indexCantVh][indexDias].aparejador = aparejador
          ? aparejador.numero_documento
          : null;
        this.form[indexCantVh][indexDias].aparejador_name = aparejador
          ? `${aparejador.nombres} ${aparejador.apellidos}`
          : null;
        this.form[indexCantVh][indexDias].aparejador_id = aparejador
          ? aparejador.id
          : null;
      } else {
        // Se asignan los valores al formulario
        for (let i = 0; i < this.asignacion.cantidad_servicios; i++) {
          if (
            this.hoy <=
              moment(this.form[indexCantVh][i].fecha).format("YYYY-MM-DD") &&
            !this.form[indexCantVh][i].id
          ) {
            this.listasForms.aparejador[indexCantVh] = aparejador
              ? aparejador.numero_documento
              : null;
            this.listasForms.aparejador_name[indexCantVh] = aparejador
              ? `${aparejador.nombres} ${aparejador.apellidos}`
              : null;
            this.form[indexCantVh][i].aparejador = aparejador
              ? aparejador.numero_documento
              : null;
            this.form[indexCantVh][i].aparejador_name = aparejador
              ? `${aparejador.nombres} ${aparejador.apellidos}`
              : null;
            this.form[indexCantVh][i].aparejador_id = aparejador
              ? aparejador.id
              : null;
          }
        }
      }
    },

    async validarStatus(
      dato,
      tipo,
      fecha = null,
      fecha_ini = null,
      fecha_fin = null,
      tipo_operario = null
    ) {
      let me = this;
      let url = "";
      let entidad = null;
      if (tipo == "C") {
        entidad = "Conductor";
        url = "/api/cs/postulacionesNacionales/listaConductores";
      } else if (tipo == "V") {
        entidad = "Vehiculo";
        url = "/api/cs/postulacionesNacionales/listaVehiculos";
      } else if (tipo == "R") {
        entidad = "Remolque";
        url = "/api/cs/postulacionesNacionales/listaRemolques";
      } else if (tipo == "E") {
        entidad = "Equipo";
        url = "/api/cs/postulacionesNacionales/listaEquipos";
      } else if (tipo == "O") {
        entidad = "Operario";
        url = "/api/cs/postulacionesNacionales/listaOperarios";
      }

      let params = {
        entidad,
        dato,
        fecha: fecha,
        fecha_ini: fecha_ini,
        fecha_fin: fecha_fin,
        tipo_operario: tipo_operario,
      };

      let res = null;
      await axios
        .get(url, { params })
        .then((response) => {
          res = response.data;
        })
        .catch(function(e) {
          me.$swal({
            icon: "error",
            title: "Ocurrió un error - " + e,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
      return res;
    },

    async msgDocumentacion(res) {
      if (res.validacion.error) {
        let title = "";
        let erroHtml = "<div>";
        if (res.validacion.error.msg) {
          title = res.validacion.error.msg;
        }
        if (res.validacion.error.documentos) {
          title = "Aviso en documentación";
          if (res.validacion.error.documentos.obligatorios) {
            erroHtml += "<b>Pendiente:</b><br>";
            erroHtml += res.validacion.error.documentos.obligatorios.join(
              "<br>"
            );
          }
          if (res.validacion.error.documentos.vencidos) {
            erroHtml += "<br><b>Vencidos:</b><br>";
            for (
              let i = 0;
              i < res.validacion.error.documentos.vencidos.length;
              i++
            ) {
              erroHtml +=
                res.validacion.error.documentos.vencidos[i].documento +
                " - " +
                res.validacion.error.documentos.vencidos[i].fecha_vencimiento +
                "<br>";
            }
          }
          if (res.validacion.error.documentos.no_verificados) {
            erroHtml += "<br><b>No Verificados:</b><br>";
            erroHtml += res.validacion.error.documentos.no_verificados.join(
              "<br>"
            );
          }
        }
        erroHtml += "</div>";
        await this.$swal({
          html: erroHtml,
          showCancelButton: false,
          focusConfirm: true,
          icon: "error",
          title,
          showConfirmButton: false,
          showCloseButton: true,
          footer: `
            <small style='text-align:center'>
              <b>Desde el 1 de octubre se aplicarán las restricciones</b>, 
              por favor tenga al día la documentación de sus vehículos, 
              para mas información comuniquese con la mesa de ayuda al siguiente número 
              <b>3176431376 Opción 3</b>
            </small>
          `,
        });
      }
    },

    editDetPost(indexDet, indexDias) {
      this.selectVehi = `${indexDet}_${indexDias}`;
    },

    async save(indexDet, indexDias) {
      const form = await this.validarForm(indexDet, indexDias);
      if (form.length > 0) {
        // Si hay formularios se guarda
        this.cargando = true;
        axios({
          method: "PUT",
          url: "/api/cs/postulacionesNacionales",
          data: form,
        })
          .then((response) => {
            this.cargando = false;
            this.$swal({
              icon: "success",
              title: "Los datos de la postulación se guardaron exitosamente...",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
            response.data.SolicitudEstado ? this.back() : this.init();
          })
          .catch((e) => {
            this.cargando = false;
            this.$swal({
              icon: "error",
              title: "Ocurrió un error, vuelva a intentarlo..." + e,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      } else {
        this.$swal({
          icon: "error",
          title: `Faltan datos necesarios para la postular el ${
            this.asignacion.solicitud_tipo_servicio.tipo_servicio == 1
              ? "Vehículo"
              : "Equipo"
          }.`,
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 4000,
          timerProgressBar: true,
        });
      }
    },

    validarForm(indexCantVh, indexDias) {
      let form = [];
      if (indexDias != null) {
        if (this.asignacion.solicitud_tipo_servicio.tipo_servicio == 1) {
          // Es transporte
          if (
            (this.form[indexCantVh][indexDias].aplicaRemol &&
              this.form[indexCantVh][indexDias].equipo_id &&
              this.form[indexCantVh][indexDias].persona_id &&
              this.form[indexCantVh][indexDias].remolque_id !== null &&
              this.form[indexCantVh][indexDias].date_llegada_vh &&
              this.form[indexCantVh][indexDias].hora_llegada_vh) ||
            (!this.form[indexCantVh][indexDias].aplicaRemol &&
              this.form[indexCantVh][indexDias].equipo_id &&
              this.form[indexCantVh][indexDias].persona_id &&
              this.form[indexCantVh][indexDias].date_llegada_vh &&
              this.form[indexCantVh][indexDias].hora_llegada_vh)
          ) {
            form.push(this.form[indexCantVh][indexDias]);
          }
        } else if (
          this.asignacion.solicitud_tipo_servicio.tipo_servicio == 2 &&
          this.form[indexCantVh][indexDias].equipo_id &&
          this.form[indexCantVh][indexDias].persona_id &&
          this.form[indexCantVh][indexDias].aparejador_id &&
          this.form[indexCantVh][indexDias].date_llegada_vh &&
          this.form[indexCantVh][indexDias].hora_llegada_vh
        ) {
          // Es Izaje
          form.push(this.form[indexCantVh][indexDias]);
        }
      } else {
        for (let dias = 0; dias < this.asignacion.cantidad_servicios; dias++) {
          if (!this.form[indexCantVh][dias].id) {
            if (this.asignacion.solicitud_tipo_servicio.tipo_servicio == 1) {
              // Es transporte
              if (
                (this.remolque &&
                  this.form[indexCantVh][dias].equipo_id &&
                  this.form[indexCantVh][dias].persona_id &&
                  this.form[indexCantVh][dias].remolque_id !== null &&
                  this.form[indexCantVh][dias].date_llegada_vh &&
                  this.form[indexCantVh][dias].hora_llegada_vh) ||
                (!this.remolque &&
                  this.form[indexCantVh][dias].equipo_id &&
                  this.form[indexCantVh][dias].persona_id &&
                  this.form[indexCantVh][dias].date_llegada_vh &&
                  this.form[indexCantVh][dias].hora_llegada_vh)
              ) {
                form.push(this.form[indexCantVh][dias]);
              }
            } else if (
              this.asignacion.solicitud_tipo_servicio.tipo_servicio == 2 &&
              this.form[indexCantVh][dias].equipo_id &&
              this.form[indexCantVh][dias].persona_id &&
              this.form[indexCantVh][dias].aparejador_id &&
              this.form[indexCantVh][dias].date_llegada_vh &&
              this.form[indexCantVh][dias].hora_llegada_vh
            ) {
              // Es Izaje
              form.push(this.form[indexCantVh][dias]);
            }
          }
        }
      }
      return form;
    },

    destroy(indexCantVh, indexDias) {
      this.$swal({
        title: "Está seguro de eliminar este detalle de la postulación?",
        text: "Los cambios no se pueden revertir!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Si, Eliminar!",
      }).then(async (result) => {
        if (result.value) {
          this.cargando = true;
          await axios
            .delete(
              "/api/cs/postulacionesNacionales/" +
                this.form[indexCantVh][indexDias].id
            )
            .then(() => {
              this.cargando = false;
              this.init();
              this.$swal({
                icon: "success",
                title:
                  "Se eliminó el detalle de la postulación exitosamente...",
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
              });
            })
            .catch((e) => {
              this.cargando = false;
              this.$swal({
                icon: "error",
                title: "Ocurrió un error, vuelva a intentarlo..." + e,
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
              });
            });
        }
      });
    },

    back() {
      return this.$router.replace("/Cs/PostulacionesNacionales");
    },
  },

  async mounted() {
    await this.init();
  },
};
</script>
